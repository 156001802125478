<template>
    <div id="categoryInvoices" class="grid mt-4 gap-x-2" :class="{'grid-cols-3': openCheckout}">
        <div class="accordion" :class="[openCheckout ? 'col-span-2' : 'col-span-3']" role="tablist">
            <CustomerDropdown class="ml-auto mb-2 " @filterCustomerId="filterInvoices" v-can="'filter invoices'"/>
            <!-- ist -->
            <b-card v-for="(category,name) in categoryInvoices" :key="name" no-body class="mb-4">
                <b-card-header style="cursor: pointer" :id="`accordion-${name}-header`"
                               @click="toggleCollapse(name)"
                               header-tag="header" class="p-1 border"
                               role="tab">
                    <div class="d-flex flex-column flex-md-row items-center justify-content-between p-2">

                        <div class="font-weight-bold text-capitalize text-center">
                            <b-icon v-if="accordions[name].open" class="ml-1 mr-3" icon="dash-circle"
                                    scale="1.65"
                                    aria-hidden="true"></b-icon>
                            <b-icon v-else class="ml-1 mr-3" icon="plus-circle" scale="1.65"
                                    aria-hidden="true"></b-icon>
                            <span> {{ $t(name) }} </span>
                        </div>
                        <div class="d-flex items-center">
                            <div class="mr-3">{{ $t('Total Amount') }}: {{ invoiceInfo[name].total | comma_separated}}</div>
                            <div class="mr-3">{{ $t('Invoices') }}: {{ invoiceInfo[name].invoice_count }} </div>
                            <div class="mr-3">{{ $t('Due Amount') }}: {{ invoiceInfo[name].due_total  | comma_separated}} </div>
<!--                            <div class="mr-3">OverDue: {{ invoiceInfo[name].overdue_total }}</div>-->
                        </div>
                    </div>
                </b-card-header>
                <b-collapse :id="`accordion-${name}`" :class="{show: accordions[name].open}" accordion="my-accordion"
                            v-show="accordions[name].open" role="tabpanel">
                    <b-card-body class="border-bottom ">
<!--                        <b-table head-variant="dark" small responsive fixed :items="category['no_contract']"
                                 :tbody-tr-class="rowClass"
                                 class="shadow-sm text-sm text-center" show-empty bordered :fields="fields">-->
                            <!--                            <template #table-colgroup="scope">-->
                            <!--                                <col-->
                            <!--                                    v-for="field in scope.fields"-->
                            <!--                                    :key="field.key"-->
                            <!--                                    :style="{ width: field.key === 'pay_amount' ? '80px' : '120px' }"-->
                            <!--                                >-->
                            <!--                            </template>-->
<!--                            <template #cell(pay)="data">
                                <div class="text-center">
                                    <b-form-checkbox
                                        v-if="data.item.status!='paid'" :checked="hasChecked(data.item.id,name)"
                                        @change="(checked) => checkoutHandler(checked,data.item,name)">
                                    </b-form-checkbox>
                                </div>
                            </template>
                            <template #cell(number)="data">
                                <p> {{ data.item.number }}</p>
                            </template>

                            <template #cell(installment.contract.number)="data">
                                <b-link v-if="data.item.installment" class="text-primary"
                                        :to='`/contract-details/${data.item.installment.contract.number}`'>
                                    {{ data.item.installment.contract.number }}
                                </b-link>
                            </template>

                            <template #cell(created_at)="data">
                                {{ data.item.created_at | moment }}
                            </template>
                            <template #cell(due_date)="data">
                                {{ data.item.due_date | moment }}
                            </template>
                            <template #cell(payments)="data">
                                <div v-if="data.item.payments.length">
                                    {{
                                        moment(data.item.payments[data.item.payments.length - 1].created_at).format('DD/MM/YYYY')
                                    }}
                                </div>
                                <a @click.prevent="viewPaymentHistory(data.item.payments)"
                                   v-if="data.item.payments.length> 1"
                                   href="/payment-history">Payment History
                                </a>
                            </template>
                            <template #cell(paymentMethod)="data">
                                <div v-if="data.item.payments.length">
                                    {{ data.item.payments[data.item.payments.length - 1].payment_method.name }}
                                </div>
                            </template>
                            <template #cell(pay_amount)="data">
                                <InvoicePayAmount :data=data :name="name"
                                                  :checkedInvoices="checkedInvoices"
                                                  @getBackCheckedInvoices="updateInvoices"
                                ></InvoicePayAmount>
                            </template>
                        </b-table>-->
                        <b-card class="mb-2" body-class="p-0 "
                                v-for="contract in Object.keys(category).filter(item => item!=='no_contract')"
                                :key="contract">
                            <b-card-header @click="toggleCollapse(contract)"
                                           style="cursor: pointer">
                                <div class="font-weight-bold text-capitalize">
                                    <b-icon v-if="accordions[contract].open" class="ml-1 mr-3"
                                            icon="dash-circle"
                                            scale="1.65"
                                            aria-hidden="true"></b-icon>
                                    <b-icon v-else class="ml-1 mr-3" icon="plus-circle" scale="1.65"
                                            aria-hidden="true"></b-icon>
                                    <span> {{ contract.replace('_', ' ') }}  </span>
                                    <span class="float-right"> {{ individual_contract_sum(category[contract]).toFixed(2) | comma_separated}} SAR</span>
                                </div>
                            </b-card-header>
                            <b-collapse :class="{show: accordions[contract].open}" v-show="accordions[contract].open"
                                        :id="contract">
                                <b-table  head-variant="dark"  small fixed responsive :items="category[contract]"
                                         :tbody-tr-class="rowClass"
                                         class="shadow-sm text-center text-sm" show-empty bordered :fields="fields">
                                    <!--                                    <template #table-colgroup="scope">-->
                                    <!--                                        <col-->
                                    <!--                                            v-for="field in scope.fields"-->
                                    <!--                                            :key="field.key"-->
                                    <!--                                            :style="{ width: field.key === 'pay_amount' ? '80px' : '120px' }"-->
                                    <!--                                        >-->
                                    <!--                                    </template>-->
                                    <template #cell(pay)="data">
                                        <div class="text-center">
                                            <b-form-checkbox v-if="data.item.status!='paid'" :checked="hasChecked(data.item.id,name)"
                                                @change="(checked) => checkoutHandler(checked,data.item,name)">
                                            </b-form-checkbox>
                                        </div>
                                    </template>
                                    <template #cell(number)="data">
                                        <p> {{ data.item.number }}</p>
                                    </template>

                                    <template #cell(installment.contract.number)="data">
                                        <b-link v-if="data.item.installment" class="text-primary"
                                                :to='`/contract-details/${data.item.installment.contract.number}`'>
                                            {{ data.item.installment.contract.number }}
                                        </b-link>
                                    </template>

                                    <template #cell(installment.created_at)="data">
                                        {{ data.item.installment.created_at | moment }}
                                    </template>
                                    <template #cell(total)="data">
                                        {{ data.item.total | comma_separated }}
                                    </template>
                                    <template #cell(amount)="data">
                                        {{ data.item.amount | comma_separated }}
                                    </template>
                                    <template #cell(balance)="data">
                                        {{ data.item.balance | comma_separated }}
                                    </template>

                                    <template #cell(paid_date)="data">
                                        {{ data.item.paid_date | moment }}
                                    </template>
                                    <template #cell(installment.due_date)="data">
                                        {{ data.item.installment.due_date | moment }}
                                    </template>
                                    <template #cell(status)="data">

                                        {{ data.item.status =='undue'?'upcoming':data.item.status  }}
                                    </template>
                                    <template #cell(payments)="data">
                                        <div v-if="data.item.payments.length">
                                            {{
                                                data.item.payments[data.item.payments.length - 1].created_at | moment
                                            }}
                                        </div>
                                        <a @click.prevent="viewPaymentHistory(data.item.payments)"
                                           v-if="data.item.payments.length> 1"
                                           href="/payment-history">Payment History
                                        </a>
                                    </template>
<!--                                    <template #cell(paymentMethod)="data">
                                        <div v-if="data.item.payments.length">
                                            <span v-if=" data.item.payments[data.item.payments.length - 1]"></span>
                                            {{ data.item.payments[data.item.payments.length - 1].payment_method.name }}
                                        </div>
                                    </template>-->
                                    <template #cell(pay_amount)="data">
                                        <InvoicePayAmount
                                            :data=data
                                            :name="name"
                                            :checkedInvoices="checkedInvoices"
                                             @getBackCheckedInvoices="updateInvoices"
                                        ></InvoicePayAmount>
                                    </template>
                                </b-table>
                            </b-collapse>
                        </b-card>
                    </b-card-body>
                </b-collapse>
            </b-card>
        </div>

        <!-- checkout panel -->
        <InvoiceCheckoutList
            @paid="clearCheckout"
            :invoices="checkedInvoices"
            v-if="openCheckout"
            @cancelPayment="cancelPayment"
            @getCheckoutHandler="checkoutHandler"

        ></InvoiceCheckoutList>
        <!-- Payment History Modal -->
        <b-modal hide-footer hide-header centered id="paymentHistoryModal">
            <PaymentHistory :payments="payments"/>
        </b-modal>
    </div>
</template>

<script>
import moment from "moment";
import InvoiceCheckoutList from "@/components/Invoice/InvoiceCheckoutList";
import {cloneDeep} from "lodash";
// import isEmpty from "lodash.isempty";
import InvoicePayAmount from "@/components/Invoice/InvoicePayAmount";
import isEmpty from "lodash.isempty";
import PaymentHistory from "@/components/Invoice/PaymentHistory";
import CustomerDropdown from "@/components/User/CustomerDropdown";
import checkoutMixin from "../mixins/checkoutMixin";


export default {
    name: "CategoryInvoices",

    components: {CustomerDropdown, InvoicePayAmount, InvoiceCheckoutList, PaymentHistory},

    data() {
        return {
            //main tables

            items: [],
            openCheckout: false,
            categoryInvoices: [],
            checkedInvoices: {},
            paymentMethods: [],
            openedAccordion: '',
            payments: [],
            customer_id: '',
            accordions: {},
            invoiceInfo: {}
        }
    },
    created() {
        this.getInvoices();
    },
    mixins: [checkoutMixin],
    methods: {
        individual_contract_sum(contracts_obj){
            let totalAmount = 0;
            contracts_obj.forEach(item => {
                totalAmount += Number(item.total)
            })
            return totalAmount;
        },
        cancelPayment() {
            this.checkedInvoices = {};
            this.openCheckout = false;
        },
        async getInvoices() {
            const {data} = await this.$axios.get(`invoicesByCategory?customer_id=${this.customer_id}`);
            this.categoryInvoices = data
            let accordions = {};
            const invoiceInfo = {};
            for (const dataKey in data) {
                accordions[dataKey] = {
                    open: false
                }
                let totals = this.calculateTotal(data[dataKey])
                invoiceInfo[dataKey] = {
                    total: totals.total.toFixed(2),
                    due_total: totals.due_total.toFixed(2),
                    overdue_total: totals.overdue_total.toFixed(2),
                    invoice_count: 0
                }
                let invoiceCount = 0
                for (const contractKey in data[dataKey]) {
                    if (contractKey !== 'no_contract') {
                        invoiceCount += data[dataKey][contractKey].length
                        accordions[contractKey] = {
                            open: false
                        }
                    }
                }
                invoiceInfo[dataKey].invoice_count = invoiceCount
            }
            const first = Object.keys(data)[0];
            accordions[first].open = true;
            this.accordions = accordions
            this.invoiceInfo = invoiceInfo
        },
        toggleCollapse(name) {
            const accordions = cloneDeep(this.accordions);
            accordions[name].open = !accordions[name].open;
            this.accordions = accordions;
        },
        rowClass(item, type) {
            if (!item || type !== 'row') return
            if (item.status == 'paid') return 'table-success'
        },
        subtotal(array) {
            return array.reduce(function (a, c) {
                return a + Number((c.balance) || 0)
            }, 0)
        },
        calculateTotal(data) {
            //data is category object
            let totalAmount = 0;
            let dueAmount = 0;
            let overdueAmount = 0;
            let totals = {
                total: totalAmount,
                due_total: dueAmount,
                overdue_total: overdueAmount
            }
            //This will iterate through each contract invoices;
            Object.values(data).forEach(contract => {
                // console.log('contract======================>',contract);

                contract.forEach(item => {
                    totalAmount += Number(item.total)
                    if (item.status === 'due') {
                        dueAmount += Number(item.balance)
                        // dueAmount += Number(item.total - item.amount)
                    }
                    if (item.status === 'overdue') {
                        overdueAmount += Number(item.total - item.amount)
                    }
                })
                // totalAmount += contract.reduce((acc, cur) => {
                //     if (status === '') {
                //         return acc + Number(cur.total)
                //     } else {
                //         let total = status === cur.status ? (Number(cur.total - cur.amount)) : 0
                //         return acc + total
                //     }
                // }, 0)
            })
            totals = {
                total: totalAmount,
                due_total: dueAmount,
                overdue_total: overdueAmount
            }
            return totals;
        },
        clearCheckout() {
            this.openCheckout = false;
            this.checkedInvoices = {}
            this.getInvoices();
        },
        updateInvoices(invoices) {
            this.checkedInvoices = invoices;
        },
        hasChecked(id, arr) {
            if (!isEmpty(this.checkedInvoices[arr])) {
                return this.checkedInvoices[arr].some(inv => inv.id === id);
            } else {
                return false;
            }
        },
        filterInvoices(id) {
            this.customer_id = id;
            this.getInvoices()
        },
        viewPaymentHistory(payments) {
            this.$bvModal.show('paymentHistoryModal')
            this.payments = payments
        },
        getInvoiceCount(category) {
            let totalInvoices = 0;
            for (const contract in category) {
                totalInvoices += category[contract].length
            }
            return totalInvoices;
        }
    },
    filters: {
        moment: function (date) {
            return moment(date).format('DD/MM/YYYY');
        }
    },
    computed:{
        fields() {
            return [
                {
                    label:  this.$t('Select'),
                    key: 'pay',
                    thStyle: {
                        width: '50px'
                    }
                },
                {
                    label: this.$t('Installment#'),
                    key: 'number',
                    thStyle: {
                        width: '60px'
                    }
                },
                {
                    label: this.$t('Contract#'),
                    key: 'installment.contract.number',
                    thStyle: {
                        width: '70px'
                    }
                },
                {
                    label: this.$t("Payer No"),
                    key: "user.payer_number",
                    visible: true,
                    thStyle: {
                        width: "60px",
                    },
                },
                {
                    label: this.$t('Customer'),
                    key: 'user.name',
                    thStyle: {
                        width: '70px'
                    }
                },
                {
                    label: this.$t('Customer Order#'),
                    key: 'user.co_number',
                    thStyle: {
                        width: '70px'
                    }
                },
                {
                    label: this.$t('Invoice Date'),
                    key: 'installment.created_at',
                    thStyle: {
                        width: '70px'
                    }
                },
                {
                    label: this.$t('Due Date'),
                    key: 'installment.due_date',
                    thStyle: {
                        width: '70px'
                    }
                },
                {
                    label: this.$t('Paid Date'),
                    key: 'paid_date',
                    thStyle: {
                        width: '70px'
                    }
                },
                {
                    label: this.$t('Total'),
                    key: 'total',
                    thStyle: {
                        width: '70px'
                    }
                },
                
                
                
                {
                    label: this.$t('Paid Amt'),
                    key: 'amount',
                    thStyle: {
                        width: '70px'
                    }
                },
                {
                    label: this.$t('Balance'),
                    key: 'balance',
                    thStyle: {
                        width: '70px'
                    }
                },
                {
                    label: this.$t('status'),
                    key: 'status',
                    thStyle: {
                        width: '70px'
                    }
                },
                // {
                //     label: this.$t('Payment Method'),
                //     key: 'paymentMethod',
                //     thStyle: {
                //         width: '70px'
                //     }
                // },
                {
                    label: this.$t('Pay Amount'),
                    key: 'pay_amount',
                    thStyle: {
                        width: '100px'
                    }
                },
        ]
        }
    },
}
</script>

<style scoped lang="scss">
@import "~@/assets/sass/_mixins.scss";

.accordion > .card > .card-header {
    border-radius: 0;
    background: #dadada;
    outline: 0;
}

:focus {
    outline: 0;
}

.checkoutSection {
    background: gray;
}

</style>
