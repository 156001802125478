import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
    'en': {
        dir: 'ltr',
        login: 'Login',
        logout: 'Logout',
        "two_factor_authentication":"Two Factor Authentication",
        "enter_code":"Enter Code:",
        "submit":"Submit",
        "Invalid Credentials":"Invalid Credentials",
        "Invalid code":"Invalid code",
        email_payer_no: 'Email Address / Payer No',
        password: 'Password',
        Password: 'Password',
        forgot_password: 'Forgot your password?',
        contact_us: 'Contact us',
        copy_rights_1: 'Copy Rights',
        copy_rights_2: 'saudi diesel equipment co. ltd',
        account_management_control_panel: 'Account management control panel',
        profile: 'Profile',
        'Home': 'Home',
        total_amount: 'Total Amount',
        "Total Amount": 'Total Amount',
        upcoming_mount: 'Upcoming Amount',
        due_amount: 'Due Amount',
        "Due Amount": 'Due Amount',
        "contracts": 'Contracts',
        "Contracts": 'Contracts',
        "Contract": 'Contracts',
        "Contract Details": 'Contract Details',
        "Open Contracts": 'Open Contracts',
        "Invoices": 'Invoices',
        "invoices": 'Invoices',
        "Invoices#": 'Invoices',
        "Invoice": 'Invoice',
        "Due Invoices": 'Due Invoices',
        "Upcoming Invoices": 'Upcoming Invoices',
        "Paid Invoices": 'Paid Invoices',

        "due Invoices": 'Due Invoices',
        "upcoming Invoices": 'Upcoming Invoices',
        "paid Invoices": 'Paid Invoices',

        "Departments": 'Departments',
        "Payments": 'Payments',
        "SADAD Transactions": 'SADAD Transactions',
        "Sadad Transactions": 'SADAD Transactions',
        "SADAD#": 'SADAD#',
        "Manage Profile": 'Manage Profile',
        "Alerts & Notifications": 'Alerts & Notifications',
        "Update Profile": 'Update Profile',
        "Help & Tools": 'Help & Tools',
        "Support Request": 'Support Request',
        "Admin": 'Admin',
        "Users": 'Users',
        "User": 'Users',
        "Roles": 'Roles',
        "Role": 'Role',
        "Permissions": 'Permissions',
        "permissions": 'Permissions',
        "Welcome": 'Welcome',
        "Dashboard": 'Dashboard',
        "Back": 'Back',
        "Financial Summary": 'Financial Summary',
        "Invoice Chart": 'Invoice Chart',
        "Payment History": 'Payment History',
        "(Last 12 Months)": '(Last 12 Months)',
        "Date":"Date",
        'Contract - Installment Reference':'Contract - Installment Reference',
        'Payment Method':'Payment Method',
        'Amount':'Amount',
        "due balance":"Due Balance",
        "upcoming balance":"Upcoming Balance",
        "paid amount":"Paid Amount",
        "Category Invoices":"Category Invoices",
        "Category":"Category",
        "CONSTRUCTION EQUIPMENTS":"CONSTRUCTION EQUIPMENTS",
        "Select Customer":"Select Customer",
        "Select":"Select",
        "ID#":"ID#",
        "Contract#":"Contract#",
        "Customer":"Customer",
        "Customer Order#":"Customer Order#",
        "Invoice Date":"Invoice Date",
        "Due Date":"Due Date",
        "status":"Status",
        "Status":"Status",
        "Paid Date":"Paid Date",
        "Total":"Total",
        "Paid Amt":"Paid Amt",
        "Balance":"Balance",
        "Pay Amount":"Pay Amount",
        "Contract Name":"Contract Name",
        "Start Date":"Start Date",
        "End Date":"End Date",
        "Installments":"Installments",
        "Installment Number":"Installment Number",
        "Sr#":"Sr#",
        "all Contracts":"All Contracts",
        "open Contracts":"Open Contracts",
        "Checkout Receipt":"Checkout Receipt",
        "Payment method is required":"Payment method is required",
        "Pay Now":"Pay Now",
        "Cancel":"Cancel",
        "Support":"Support",
        "Get in touch":"Get in touch",
        "Due Date Start":"Due Date Start",
        "Due Date End":"Due Date End",
        "Search by invoice/contract":"Search by invoice/contract",
        "Export To Excel":"Export To Excel",
        "Installment Ref.":"Installment Ref",
        "Department":"Department",
        "undue Invoices":"UnDue Invoices",
        "Department List":"Department List",
        "Add New Department":"Add New Department",
        "Name":"Name",
        "Actions":"Actions",
        "Save":"Save",
        "Confirm Password":"Confirm Password",
        "Image":"Image",
        "All Payments":"All Payments",
        "Description":"Description",
        "Bill#":"Bill#",
        "Sadad Transactions Invoices":"SADAD TRANSACTIONS INVOICES",
        "Expiry Date":"Expiry Date",
        "Issue Date":"Issue Date",
        "Notifications":"Notifications",
        "Alert settings":"Alert settings",
        "Profile":"Profile",
        "First Name":"First Name",
        "Last Name":"Last Name",
        "Phone":"Phone",
        "Email":"Email",
        "CRR ID":"CRR ID",
        "Customer No":"Customer No",
        "Payer No":"Payer No",
        "Address":"Address",
        "Filter by role":"Filter by Role:",
        "Add New User":"Add New User",
        "Add New Role":"Add New Role",
        "Created Date":"Created Date",
        "Add New Permission":"Add New Permission",
        "Message":"Message",
        "Send":"Send",
        "User Activity":"User Activity",
        "useractivity":"User Activity",
        "Select preferred alerts":"Select Preferred Alerts",
        "Receive email for due invoices":"Receive email for due invoices",
        "#":"#",
        "difference_balance":"Difference Balance",

    },
    'ar': {
        dir: 'rtl',
        login: 'تسجيل الدخول',
        logout: 'تسجيل الخروج',
        "two_factor_authentication":"توثيق ذو عاملين",
        "enter_code":"ادخل الرمز",
        "submit":"إرسال",
        "Invalid Credentials":"بيانات الاعتماد غير صالحة",
        "Invalid code":"الرمز غير صحيح",
        email_payer_no: 'البريد الإلكتروني /رقم الدافع',
        password: 'كلمة السر',
        Password: 'كلمة السر',
        forgot_password: 'نسيت كلمة السر',
        contact_us: 'تواصل معنا',
        copy_rights_1: 'حقوق النسخ',
        copy_rights_2: 'الشركة السعودية لمعدات الديزل المحدودة',
        account_management_control_panel: 'لوحة تحكم إدارة الحساب',
        profile: 'الملف الشخصي',
        'Home': 'الصفحة الرئيسية',
        total_amount: 'المبلغ الإجمالي',
        "Total Amount": 'المبلغ الإجمالي',
        upcoming_mount: 'المبلغ القادم',
        due_amount: 'المبلغ المستحق',
        "Due Amount": 'المبلغ المستحق',
        "contracts": 'العقود',
        "Contracts": 'العقود',
        "Contract": 'العقود',
        "Contract Details": 'تفاصيل العقد',
        "Open Contracts": 'العقود المفتوحة',
        "Invoices": 'الفواتير',
        "invoices": 'الفواتير',
        "Invoices#": 'الفواتير',
        "Invoice": 'الفواتير',
        "Due Invoices": 'الفواتير المستحقة',
        "Upcoming Invoices": 'الفواتير القادمة',
        "Paid Invoices": 'لفواتير المدفوعة',
        "due Invoices": 'الفواتير المستحقة',
        "upcoming Invoices": 'الفواتير القادمة',
        "paid Invoices": 'لفواتير المدفوعة',
        "Departments": 'الأقسام',
        "Payments": 'المدفوعات',
        "SADAD Transactions": 'معاملات سداد',
        "Sadad Transactions": 'معاملات سداد',
        "SADAD#": 'رقم السداد',
        "Manage Profile": 'إدارة الملف الشخصي',
        "Alerts & Notifications": 'التنبيهات والإخطارات',
        "Update Profile": 'تحديث الملف',
        "Help & Tools": 'المساعدة والأدوات',
        "Support Request": 'طلب الدعم',
        "Admin": 'المسؤول ',
        "Users": 'مستخدمين',
        "User": 'مستخدمين',
        "Roles": 'أدوار',
        "Role": 'دور',
        "Permissions": 'صلاحيات',
        "permissions": 'صلاحيات',
        "Welcome": 'مرحبا',
        "Dashboard": 'لوحة القيادة',
        "Back": 'للخلف',
        "Financial Summary": 'الملخص المالي',
        "Invoice Chart": 'مخطط الفاتورة',
        "Payment History": 'سجل أو تاريخ الدفعة',
        "(Last 12 Months)": '( أخر 12 شهر ) ',
        "Date":"التاريخ",
        'Contract - Installment Reference':'عقد / مرجع الدفعات ',
        'Payment Method':'طريقة الدفع أو السداد',
        'Amount':'المبلغ',
        "due balance":"الرصيد المستحق",
        "upcoming balance":"الرصيد القادم",
        "paid amount":"المبلغ المدفوع",
        "Category Invoices":"الفواتير الخاصة بالفئة",
        "Category":"الفئة",
        "CONSTRUCTION EQUIPMENTS":"معدات البناء",
        "Select Customer":"إختر العميل",
        "Select":"إختر",
        "ID#":"بطاقة تعريف",
        "Contract#":"رقم العقد",
        "Customer":"العميل",
        "Customer Order#":"رقم طلب العميل",
        "Invoice Date":"تاريخ الفاتورة",
        "Due Date":"تاريخ الإستحقاق",
        "status":"الحالة",
        "Status":"الحالة",
        "Paid Date":"تاريخ الدفع",
        "Total":"المجموع",
        "Paid Amt":"المبلغ المدفوع",
        "Balance":"الرصيد",
        "Pay Amount":"مقدار الدفعة",
        "Contract Name":"اسم العقد",
        "Start Date":"تاريخ البدء",
        "End Date":"تاريخ الإنتهاء",
        "Installments":"أقساط أوم",
        "Installment Number":"رقم القسط",
        "Sr#":"الرقم التسلسلي",
        "all Contracts":"جميع العقود",
        "open Contracts":"العقود المفتوحة",
        "Checkout Receipt":"إيصال الخروج",
        "Payment method is required":"طريقة الدفع مطلوبة",
        "Pay Now":"ادفع الآن",
        "Cancel":"يلغي",
        "Support":"الدعم",
        "Get in touch":"ابقى على تواصل",
        "Due Date Start":"بداية تاريخ الاستحقاق",
        "Due Date End":"نهاية تاريخ الاستحقاق",
        "Search by invoice/contract":"البحث عن طريق الفاتورة / العقد",
        "Export To Excel":"الحصول على نسخة ملف إكسيل",
        "Installment Ref.":"مرجع الدفعة",
        "Department":"الأقسام",
        "undue Invoices":"الفواتير غير المستحقة",
        "Department List":"قائمة الأقسام",
        "Add New Department":"إضافة قسم جديد",
        "Name":"الإسم",
        "Actions":"إجراء",
        "Save":"حفظ",
        "Confirm Password":"تأكيد الرقم السري",
        "Image":"صورة",
        "All Payments":"جميع المدفوعات",
        "Description":"الوصف",
        "Bill#":"رقم الفاتورة",
        "Sadad Transactions Invoices":"معاملات سداد",
        "Expiry Date":"تاريخ الإنتهاء",
        "Issue Date":"تاريخ الإصدار",
        "Notifications":"إشعارات",
        "Alert settings":"إعدادات التنبية",
        "Profile":"الملف الشخصي",
        "First Name":"الإسم الأول",
        "Last Name":"الإسم الأخير",
        "Phone":"الهاتف",
        "Email":"البريد الإلكتروني",
        "CRR ID":"معرف ال CRR",
        "Customer No":"رقم العميل",
        "Payer No":"رقم الدافع",
        "Address":"العنوان",
        "Filter by role":"تصفية حسب  الدور",
        "Add New User":"اضافة مستخدم جديد",
        "Add New Role":"إضافة وظيفة - دور جديد",
        "Created Date":"تاريخ الإنشاء",
        "Add New Permission":"إضافة صلاحية",
        "Message":"رسالة",
        "Send":"ارسال",
        "User Activity":"نشاط المستخدم",
        "useractivity":"نشاط المستخدم",
        "Select preferred alerts":"إختر التنبيهات المفضلة",
        "Receive email for due invoices":"استلام بريد إلكتروني للفواتير المستحقة",
        "#":"#",
        "difference_balance":"ميزان الفرق",

    }
}

export const i18n = new VueI18n({
    locale: localStorage.getItem("language_selected") === null ? 'en' : localStorage.getItem("language_selected"),
    fallbackLocale: 'en',
    messages
})
