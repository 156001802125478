<template>
    <div>


        <h1 class="py-3 text-capitalize arabicDir">{{ $t(this.$route.params.type+' Contracts') }} </h1>

        <div class="d-flex items-center justify-content-end arabicDirInit">
            <!--get customer_id in event and use it here-->
            <CustomerDropdown @filterCustomerId="filterContracts" v-can="'filter invoices'"/>
        </div>
        <b-card class="shadow-sm mt-2" body-class="p-0">
            <b-table head-variant="dark" class="text-center" small
                     :items="items"
                     show-empty bordered
                     :fields="fields"
                     :filter="filterTag"
                     responsive>
<!--                <template #cell(number)="data">-->
<!--                    <b-link class="text-primary" :to="{name:'ContractDetails',params:{number: data.item.number}}">-->
<!--                        {{ data.item.number }}-->
<!--                    </b-link>-->
<!--                </template>-->

<!--                implement it later-->
                <template  #cell(start_date)="data">
                    <span v-if="data.item.start_date">{{ data.item.start_date | moment }}</span>
                </template>
                <template  #cell(end_date)="data">
                    <span v-if="data.item.end_date">{{ data.item.end_date | moment }}</span>
                </template>
                <template #cell(number)="data">
                    <b-link class="text-primary" :to="`/contract-details/${data.item.number}`">
                        {{ data.item.number }}
                    </b-link>
                </template>
            </b-table>

        </b-card>
    </div>
</template>

<script>
import 'vue-select/dist/vue-select.css';
import CustomerDropdown from "@/components/User/CustomerDropdown";
import moment from "moment";

export default {
    name: "Contracts",
    components: {
        CustomerDropdown,
        // Select: Select
    },
    computed:{
        fields() {
            return [
                {
                    label: this.$t('Contract#'),
                    key: 'number'
                },
                {
                    label: this.$t('Contract Name'),
                    key: 'name'
                },
                {
                    label: this.$t('Start Date'),
                    key: 'start_date'
                },
                {
                    label: this.$t('End Date'),
                    key: 'end_date'
                },
                {
                    label: this.$t('Status'),
                    key: 'status'
                },
                {
                    label: this.$t('Payer No'),
                    key: 'user.payer_number'
                },
                {
                    label: this.$t('Category'),
                    key: 'category.name'
                },
                {
                    label: this.$t('Customer Order#'),
                    key: 'user.co_number',

                },
            ]
        }
    },
    data() {
        return {
            items: [],
            filterTag: '',
            customer_id: '',
        }
    },
    created() {
        this.getContracts()
    },
    filters: {
        moment: function (date) {
            return moment(date).format("DD/MM/YYYY");
        },
    },
    methods: {
        async getContracts() {
            const {data} = await this.$axios.get(`contract?customer_id=${this.customer_id}`);
            this.items = [...data];
            //duplicate code
            if (this.$route.params.type == 'all') {
                this.filterTag = '';
                return;
            } else {
                this.filterTag = this.$route.params.type;
            }
        },
        filterContracts(value) {
            this.customer_id = value;
            this.getContracts();
        }
    },
    watch: {
        $route(to) {
            this.type = to.params.type;
            if (to.params.type == 'all') {
                this.filterTag = '';
                return;
            } else {
                this.filterTag = to.params.type;
            }
        }
    },
}
</script>

<style scoped>

</style>
