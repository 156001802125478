<template>
    <div class="d-flex items-center vh-100">
        <div id="loginFrame" class="mx-auto">
            <div class="text-center mb-4">
                <img class="w-50" src="../assets/images/logo.png" alt="">
            </div>
            <b-card >
<!--                <p>{{ $t('dir') }}</p>-->
                <div class=" grid text-5xl font-weight-light text-center text-muted">{{ $t('login') }}</div>
                <form @submit="login" action="" method="post" class="mt-4 w-75 mx-auto loginForm">
                    <validation-observer ref="loginForm" class="d-flex flex-column w-100">
                        <b-form-group   label-cols="12" :label="$t('email_payer_no')">
                            <text-validator v-model="email_payer_no" name="email_payer_no" rules="required"
                                            type="text"></text-validator>
                        </b-form-group>
                        <b-form-group class="mt-4"  label-cols="12"   :label="$t('password')">
                            <text-validator name="password" v-model="password" rules="required"
                                            type="password"></text-validator>
                        </b-form-group>
                        <b-form-group class="mt-4" label-cols="12">
                            <button class="primary-btn green arabicDir">{{ $t('login') }}</button>
<!--                            <div class="mt-2 arabicDir">-->
<!--                                <b-link to="/forgot-password">{{ $t('forgot_password') }}</b-link>-->
<!--                            </div>-->
                        </b-form-group>

                    </validation-observer>

                </form>



            </b-card>
            <b-card>
                <b-form-group class="float-right w-15" >
                    <b-form-select
                        id="langSelect"
                        class="mr-2 text-uppercase"
                        size="sm"
                        v-model="language"
                        :options="languages"
                    ></b-form-select></b-form-group>
            </b-card>
        </div>
    </div>
</template>

<script>
import TextValidator from "@/components/Validators/TextValidator";
import {ValidationObserver} from "vee-validate";
import {errorAlert} from "@/components/Theme/Alerts";
import {mapActions} from "vuex";
import {i18n} from "@/plugins/i18n";

export default {
    name: "Login",
    components: {TextValidator, ValidationObserver},
    data() {
        return {
            languages: ["en", "ar"],
            language: this.systemLanguage(),
            email_payer_no: '',
            password: ''
        }
    },
    methods: {
        ...mapActions(["changeLanguage"]),
        systemLanguage () {
            if (localStorage.getItem("language_selected") === null) {
                return  this.$store.state.lang;
            }else{
                return  localStorage.getItem("language_selected");
            }
        },

        async login(e) {
            e.preventDefault();
            this.$isLoading(true)
            const valid = await this.$refs.loginForm.validate();
            if (valid) {
                try {
                    const user = {
                        email_payer_no: this.email_payer_no,
                        password: this.password,
                    }
                    const {data} = await this.$axios.post('login', user);
                    localStorage.setItem('user_email', data.user_email);
                    this.$isLoading(false)
                   /* this.$axios.defaults.headers.Authorization = `Bearer ${data.token}`
                    localStorage.setItem('user', JSON.stringify(data));
                    this.$router.push('home')*/
                    this.$router.push('two-factor-authentication')
                } catch (e) {
                    this.$isLoading(false)
                    errorAlert('Invalid Credentials', 'Email/Payer No or Password are incorrect')
                }
            }else{
                this.$isLoading(false)
                errorAlert('Invalid Credentials', 'Email/Payer No or Password validation Failed')

            }
        }
    },
    watch: {
        language: function (val, old) {
            if (val !== old) {
                this.changeLanguage(val);
                i18n.locale = val;
                const {locale} = i18n
                const htmlContainer = document.querySelector('html');
                const bodyContainer = document.querySelector('body');
                const {dir} = i18n.getLocaleMessage(locale);
                bodyContainer.setAttribute('class', locale);
                htmlContainer.setAttribute('lang', locale);
                const currentDirection = htmlContainer.getAttribute('dir');

                if (currentDirection !== dir) {
                    htmlContainer.setAttribute('dir', dir)
                }


            }
        },
    },
}
</script>

<style scoped lang="scss">
@import "~@/assets/sass/_mixins.scss";

#loginFrame{
    width: 90%;
    @include lg{
        width: 70%;
    }
    @include xl{
        width: 50%;
    }
}


</style>


