<template>
    <div class="mt-4">
        <div class="">

            <div class="d-flex justify-content-between">
                <h3 class="text-capitalize">{{ $t('All Payments') }} </h3>
                <div v-if="!invoice_id" class="d-flex items-center justify-content-end">
<!--                    <h3 class="py-3 text-capitalize arabicDir">{{ $t('All Payments') }} </h3>-->
                    <date-picker
                        v-model="start_date"
                        @change="getPayments"
                        format="YYYY-MM-DD"
                        type="date"
                        :placeholder="$t('Paid Date Start')"
                        style="width: 150px"
                        class="bg-white ml-2"
                        v-can="'filter invoices'"
                    ></date-picker>
                    <date-picker
                        style="width: 150px"
                        class="bg-white ml-2"
                        v-model="end_date"
                        @change="getPayments"
                        format="YYYY-MM-DD"
                        type="date"
                        :placeholder="$t('Paid Date End')"
                        v-can="'filter invoices'"
                    ></date-picker>
                    <b-input
                        style="width: 200px"
                        class="bg-white ml-2"
                        v-model="search"
                        @keyup="getPaymentsBySearch"
                        @blur="getPayments"
                        :placeholder="$t('Search by invoice')"
                        v-can="'filter invoices'"
                    ></b-input>

                </div>
                <div v-if="invoice_id" class="d-flex items-center justify-content-end">
                    <h3 v-if="paymentItems.data[paymentItems.data.length-1].invoices[0].number" class="py-3 text-capitalize arabicDir">{{ $t('Invoice Number') }}: {{this.paymentItems.data[this.paymentItems.data.length-1].invoices[0].number }} </h3>
                </div>
            </div>
        </div>
        <div class="grid ap-x-4 mt-4">

            <b-card class="shadow-sm mt-2" body-class="p-0">
                <b-table head-variant="dark" small
                         :busy="loading"
                         :items="paymentItems.data"
                         show-empty bordered
                         :fields="fields"
                         responsive
                         class="text-center"
                >
                    <template #table-busy>
                        <table-loader/>
                    </template>
                    <template #cell(created_at)="data">
                        {{ data.item.created_at | moment }}
                    </template>
                    <template #cell(amount)="data">
                        {{ data.item.amount | comma_separated }}
                    </template>
                    <template #cell(invoices)="data">
                        <div v-if="data.item.invoices[0]">
                                 <b-link
                                     v-if="data.item.invoices[0].installment"
                                     class="text-primary"
                                     :to="`/contract-details/${data.item.invoices[0].installment.contract.number}`"
                                 >
                                     {{ data.item.invoices[0].installment.contract.number}}
                                 </b-link>  <span class="ml-2"> -  {{ data.item.invoices[0].installment.ref_number}}</span>
                             </div>
                    </template>

                    <template #cell(number)="data">
                        <b-link class="text-dark" :to="{name:'ContractDetails',params:{number: data.item.number}}">
                            {{ data.item.number }}
                        </b-link>
                    </template>
                    <!--            <template #cell(due_date)="data">-->
                    <!--                {{ data.item.due_date | moment }}-->
                    <!--            </template>-->
                </b-table>

                <b-pagination
                    aria-controls="my-table"
                    size="sm"
                    per-page="2"
                    v-model="currentPage"
                    :total-rows="paymentItems.total"
                    @input="getPayments"
                    prev-text="Prev"
                    next-text="Next"
                    class="justify-content-center"
                ></b-pagination>
            </b-card>
        </div>
    </div>
</template>

<script>
import moment from "moment";

import DatePicker from "vue2-datepicker";
import TableLoader from "@/components/tableLoader";

export default {
    name: "Payments",
    components: {


        DatePicker,
        TableLoader
    },
    computed: {
        fields() {
            return [
            {
                    label: this.$t('Payer No'),
                    key: 'payer_number'
                },
                {
                    label: this.$t('Contract - Installment Reference'),
                    key: 'invoices'
                },
                {
                    label: this.$t('Paid Date'),
                    key: 'paid_date'
                },
                {
                    label: this.$t('Amount'),
                    key: 'amount'
                },
                {
                    label: this.$t('Payment Method'),
                    key: 'payment_method.name'
                },
                {
                    label: this.$t('V-ID'),
                    key: 'voucher_id'
                },
                {
                    label: this.$t('Created Date'),
                    key: 'created_at'
                },

            ]
        }
    },
    data() {
        return {
            loading: false,
            paymentItems: [],
            currentPage: 1,
            invoice_id: "",
            start_date: null,
            end_date: null,
            search: null,
            // rows:''
        }
    },
    methods: {
        getPaymentsBySearch() {
            if (this.search.length >= 3) {
                this.getPayments();
            }
        },
        async getPayments() {
            // const { data } = await this.$axios.get(`invoice?customer_id=${this.customer_id}&start_date=${start_date}&end_date=${end_date}&search=${search}&page=${this.currentPage}&type=${JSON.stringify(this.type)}&export=1`);



            try {
                this.loading = true;
                const start_date =
                    this.start_date !== null
                        ? moment(this.start_date).format("YYYY-MM-DD")
                        : null;
                const end_date =
                    this.end_date !== null
                        ? moment(this.end_date).format("YYYY-MM-DD")
                        : null;
                const search =
                    this.search !== null
                        ? encodeURIComponent(this.search.trim())
                        : null;
                const {data} = await this.$axios.get(`payment?page=${this.currentPage}&invoice_id=${this.invoice_id}&start_date=${start_date}&end_date=${end_date}&search=${search}`);
                this.paymentItems = data;

                this.loading = false;
            } catch (err) {
                this.loading = false;
            }
        },

    },
    created() {
        if (this.$route.params.id) {
            this.invoice_id = this.$route.params.id;
        }
        this.getPayments();
        // console.log(this.paymentItems);
        // console.log(this.rows);
    },
    filters: {
        moment: function (date) {
            return moment(date).format('DD/MM/YYYY');
        }
    },


}
</script>

<style scoped>

</style>
